import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import {
    IEmployee,
    IOffice,
    IShop,
    IAccount,
    IStore,
    IEmployeeCreateDto,
    IEmployeeUpdateDto,
    ICompany,
    IProject,
} from "@lib";
import { PermissionCommonSection, PermissionType, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { EmployeeModalContext, EmployeeModal } from "./modals/employee.modal";
import { PermissionsModalContext, PermissionsModal } from "./modals/permissions.modal";
import { getTableColumns } from "./settings-company-employees-defaults";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent },
})
export default class SettingsCompanyEmployees extends Vue {
    private company!: ICompany;
    private employees: IEmployee[] = [];
    private offices: IOffice[] = [];
    private shops: IShop[] = [];
    private projects: IProject[] = [];
    private stores: IStore[] = [];
    private accounts: IAccount[] = [];

    private EmployeeUseCase = this.$alt.system.usecase.createEmployeeUseCase();

    private employeeModal: EmployeeModal;
    private permissionsModal: PermissionsModal;

    public constructor() {
        super();

        this.employeeModal = new EmployeeModal();
        this.employeeModal.onCreate = this.create.bind(this);
        this.employeeModal.onUpdate = this.update.bind(this);
        this.employeeModal.onEditPermissions = this.openModalPermissions.bind(this);

        this.permissionsModal = new PermissionsModal();
        this.permissionsModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Employees, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Employees, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Employees, PermissionRight.Delete);
            },

            get readOffices(): boolean {
                return true;
            },
            get readShops(): boolean {
                return true;
            },
            get readStores(): boolean {
                return true;
            },
            get readAccounts(): boolean {
                return true;
            },

            get readSalary(): Function {
                return (employee: IEmployee): boolean => {
                    return secure.check(PermissionType.Employees, employee.id, PermissionRight.Read);
                };
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.company = await this.$info.getCompany();
            await this.selectEmployees();
            await this.selectOffices();
            await this.selectShops();
            await this.selectStores();
            await this.selectAccounts();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected async openModalCreate(): Promise<void> {
        const context: EmployeeModalContext = {
            offices: this.offices,
            shops: this.shops,
            projects: this.projects,
            accounts: this.accounts,
            stores: this.stores,
            employees: this.employees,
        };
        await this.employeeModal.show(context);
    }

    protected async openModalUpdate(employee: IEmployee): Promise<void> {
        const context: EmployeeModalContext = {
            employee: employee,
            offices: this.offices,
            shops: this.shops,
            projects: this.projects,
            accounts: this.accounts,
            stores: this.stores,
            employees: this.employees,
        };
        await this.employeeModal.show(context);
    }

    private async openModalPermissions(employee: IEmployee): Promise<void> {
        try {
            const permissions = await this.EmployeeUseCase.selectPermissions(this.company.id, employee.user as string);

            const context: PermissionsModalContext = {
                employee,
                permissions,
                offices: this.offices,
                shops: this.shops,
                projects: this.projects,
                accounts: this.accounts,
                stores: this.stores,
                employees: this.employees,
            };
            await this.permissionsModal.show(context);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async redirectToSalary(employee: IEmployee): Promise<void> {
        await this.$router.push({ name: "salary", params: { id: employee.user as string } }).catch(() => {});
    }

    private async confirmDelete(employee: any): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить сотрудника "${employee.userRef.info.name}"...`,
            "Удаление сотрудника",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(employee);
        }
    }

    private async selectEmployees(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            const employees = await this.$alt.system.usecase.createEmployeeUseCase().select(this.company.id);
            this.employees = employees.sort((a, b) => {
                if (a.userRef.info.name > b.userRef.info.name) {
                    return 1;
                }
                if (a.userRef.info.name < b.userRef.info.name) {
                    return -1;
                }
                return 0;
            });
        } catch (e: any) {
            throw new Error(`Не удалось загрузить данные:\n${e.message}`);
        }
    }

    private async selectOffices(): Promise<void> {
        try {
            if (!this.can.readOffices) {
                return;
            }

            this.offices = await this.$alt.system.usecase.createOfficeUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить филиалы:\n${e.message}`);
        }
    }

    private async selectShops(): Promise<void> {
        try {
            if (!this.can.readShops) {
                return;
            }

            this.shops = await this.$alt.system.usecase.createShopUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить магазины:\n${e.message}`);
        }
    }

    private async selectStores(): Promise<void> {
        try {
            if (!this.can.readStores) {
                return;
            }

            this.stores = await this.$alt.system.usecase.createStoreUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить склады:\n${e.message}`);
        }
    }

    private async selectAccounts(): Promise<void> {
        try {
            if (!this.can.readAccounts) {
                return;
            }

            this.accounts = await this.$alt.system.usecase.createAccountUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить счета:\n${e.message}`);
        }
    }

    private async create(dto: IEmployeeCreateDto): Promise<IEmployee | null> {
        try {
            this.$alt.loader.show();
            const emp = await this.EmployeeUseCase.create(this.company.id, dto);
            await this.selectEmployees();
            this.$alt.toast.success("Сотрудник успешно добавлен.", "Добавление");
            return emp;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(employee: IEmployee, dto: IEmployeeUpdateDto): Promise<IEmployee | null> {
        try {
            this.$alt.loader.show();
            const emp = await this.EmployeeUseCase.update(this.company.id, employee.user as string, dto);
            await this.selectEmployees();
            this.$alt.toast.success(`Сотрудник "${employee.userRef.info.name}" успешно изменён.`, "Изменение");
            return emp;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(employee: IEmployee): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.EmployeeUseCase.delete(this.company.id, employee.user as string);
            await this.selectEmployees();
            this.$alt.toast.success(`Сотрудник "${employee.userRef.info.name}" успешно удалён.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
