import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { IAccount, IAccountCreateDto, IAccountUpdateDto, ICompany, IUser } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { AccountModal } from "./modals/account.modal";
import { getTableColumns } from "./settings-company-accounts-defaults";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent },
})
export default class SettingsCompanyAccounts extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private accounts: IAccount[] = [];

    private AccountUseCase = this.$alt.system.usecase.createAccountUseCase();

    private accountModal: AccountModal;

    public constructor() {
        super();

        this.accountModal = new AccountModal();
        this.accountModal.onCreate = this.create.bind(this);
        this.accountModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Accounts, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Accounts, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Accounts, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectAccounts();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected async openModalCreate(): Promise<void> {
        await this.accountModal.show();
    }

    protected async openModalUpdate(account: IAccount): Promise<void> {
        await this.accountModal.show(account);
    }

    private async confirmDelete(account: IAccount): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить счёт: "${account.info.name}"?`,
            "Удаление счёта",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(account);
        }
    }

    private async selectAccounts(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.accounts = await this.AccountUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить счета:\n${e.message}`);
        }
    }

    private async selectAccountsForUser(): Promise<void> {
        const accounts = await this.$alt.system.usecase
            .createEmployeeUseCase()
            .selectAccounts(this.company.id, this.user.id);
        this.$info.setAccounts(accounts);
    }

    private async create(dto: IAccountCreateDto): Promise<IAccount | null> {
        try {
            this.$alt.loader.show();
            const ac = await this.AccountUseCase.create(this.company.id, dto);
            await this.selectAccounts();
            this.selectAccountsForUser(); // асинхронно
            this.$alt.toast.success("Счёт успешно создан.", "Создание");
            return ac;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать счёт:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(account: IAccount, dto: IAccountUpdateDto): Promise<IAccount | null> {
        try {
            this.$alt.loader.show();
            const ac = await this.AccountUseCase.update(this.company.id, account.id, dto);
            await this.selectAccounts();
            this.selectAccountsForUser(); // асинхронно
            this.$alt.toast.success(`Счёт "${account.info.name}" успешно изменён.`, "Изменение");
            return ac;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить счёт:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(account: IAccount): Promise<boolean> {
        try {
            this.$alt.loader.show();
            await this.AccountUseCase.delete(this.company.id, account.id);
            await this.selectAccounts();
            this.selectAccountsForUser(); // асинхронно
            this.$alt.toast.success(`Счёт "${account.info.name}" успешно удалён.`, "Удаление");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить счёт:\n${e.message}`);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }
}
