import { Vue, Component } from "vue-property-decorator";
import { BCard, BListGroup, BListGroupItem, BCardText, BAvatar, BButton } from "bootstrap-vue";
import { IPluginBinding, IPluginCard, IPluginBindingDto, ICompany } from "@lib";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { PluginConfigurationModal } from "./modals/pligin-configuration.modal";
import Plugin from "./plugin/plugin.vue";

@Component({
    components: {
        BCard,
        BListGroup,
        BListGroupItem,
        BCardText,
        BAvatar,
        BButton,
        ModalComponent,
        Plugin,
    },
})
export default class SettingsCompanyPlugins extends Vue {
    private PluginCardUseCase = this.$alt.system.usecase.createPluginCardUseCase();
    private PluginBindingUseCase = this.$alt.system.usecase.createPluginBindingUseCase();

    private company!: ICompany;
    private pluginsList: IPluginCard[] = [];
    private bindingsList: IPluginBinding[] = [];

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.isAdmin();
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.isAdmin();
            },
            get delete(): boolean {
                return secure.isAdmin();
            },
        };
    }

    private pluginSettingsModal: PluginConfigurationModal;

    public constructor() {
        super();

        this.pluginSettingsModal = new PluginConfigurationModal();
        this.pluginSettingsModal.onSave = this.saveConfiguration.bind(this);
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.company = await this.$info.getCompany();
            this.pluginsList = await this.PluginCardUseCase.select();
            await this.selectPlugins();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectPlugins(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.bindingsList = await this.PluginBindingUseCase.select(this.company.id);
            this.$info.setPlugins(this.bindingsList);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить интеграции:\n${e.message}`);
        }
    }

    private getBinding(plugin: IPluginCard): IPluginBinding | undefined {
        return this.bindingsList.find(binding => binding.code === plugin.code);
    }

    private async bind(plugin: IPluginCard): Promise<void> {
        try {
            const dto: IPluginBindingDto = {
                plugin: plugin.id,
                code: plugin.code,
                type: plugin.type,
                price: {
                    type: plugin.price.type,
                    value: plugin.price.value,
                    currency: plugin.price.currency,
                },
            };
            const binding = await this.PluginBindingUseCase.bind(this.company.id, dto);
            await this.selectPlugins();

            const bindingInList = this.bindingsList.find(b => b.id === binding?.id) ?? null;
            if (bindingInList && bindingInList.plugin && (bindingInList.plugin as any).configuration.length > 0) {
                this.configure(bindingInList);
            }
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось установить интеграцию:\n${e.message}`);
        }
    }

    private async confirmDelete(binding: IPluginBinding): Promise<void> {
        const name = binding.pluginRef?.name ?? binding.id;
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить интеграцию "${name}"?`,
            "Удаление интеграции",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.unbind(binding);
        }
    }

    private async confirmDisable(binding: IPluginBinding): Promise<void> {
        const name = binding.pluginRef?.name ?? binding.id;
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите отключить интеграцию "${name}"?`,
            "Отключение интеграции",
            { acceptText: "Отключить" },
        );

        if (result) {
            await this.disable(binding);
        }
    }

    private async confirmEnable(binding: IPluginBinding): Promise<void> {
        const name = binding.pluginRef?.name ?? binding.id;
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите включить интеграцию "${name}"?`,
            "Включение интеграции",
            { acceptText: "Включить" },
        );

        if (result) {
            await this.enable(binding);
        }
    }

    private async unbind(binding: IPluginBinding): Promise<void> {
        try {
            await this.PluginBindingUseCase.unbind(this.company.id, binding.id);
            await this.selectPlugins();
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить интеграцию:\n${e.message}`);
        }
    }

    private async disable(binding: IPluginBinding): Promise<void> {
        try {
            await this.PluginBindingUseCase.enable(this.company.id, binding.id, false);
            await this.selectPlugins();
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось отключить интеграцию:\n${e.message}`);
        }
    }

    private async enable(binding: IPluginBinding): Promise<void> {
        try {
            await this.PluginBindingUseCase.enable(this.company.id, binding.id, true);
            await this.selectPlugins();
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось включить интеграцию:\n${e.message}`);
        }
    }

    private async configure(binding: IPluginBinding): Promise<void> {
        if (!binding.pluginRef) {
            return;
        }

        const context = {
            binding: binding,
            plugin: binding.pluginRef,
        };
        await this.pluginSettingsModal.show(context);
    }

    private async saveConfiguration(orig: IPluginBinding, configuration: any): Promise<IPluginBinding | null> {
        try {
            const binding = await this.PluginBindingUseCase.configure(this.company.id, orig.id, configuration);
            await this.selectPlugins();
            this.$alt.toast.success("Настройки сохранены.");
            return binding;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось сохранить конфигурацию:\n${e.message}`);
            return null;
        }
    }
}
