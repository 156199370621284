import { IOffice, IOfficeCreateDto, IOfficeUpdateDto, Currency, Locale, IOrderType, IProductType } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    Control,
    Label,
    Panel,
    PhoneBox,
    Select,
    TextBox,
    TextChangedEventArgs,
} from "@core/components/alt-ui/controls";
import { ISelectOption } from "@core/types/common/select-options";
import {
    createDemoCompany,
    createDemoOffice,
    createDemoOrder,
    createDemoOrderType,
    createProductType,
} from "@core/controls/document-template-editor/preview-data";
import { Printer } from "@/@core/usecases/template/printer";
import { OrderPrintContext } from "@core/usecases/template/macro-replacers/order-document.macro-replacer";
import { CurrencyUtils } from "@/utils/types/currency.utils";
import { LocaleUtils } from "@/utils/types/locale.utils";

export class OfficeModal extends Modal<IOffice> {
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private tbEmail!: TextBox;
    private phPhone!: PhoneBox;
    private tbAddress!: TextBox;
    private cbCurrency!: Select<ISelectOption<Currency>>;
    private cbLocale!: Select<ISelectOption<Locale>>;
    private tbNumerationTemplate!: TextBox;
    private lblNumerationExample!: Label;
    private pnlNumerationTemplate!: Panel;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private demo: OrderPrintContext = {
        company: createDemoCompany(),
        order: createDemoOrder(),
        office: createDemoOffice(),
        orderType: createDemoOrderType(),
        productTypes: [createProductType()],
    };

    private office: IOffice | null = null;
    public onCreate: ((dto: IOfficeCreateDto) => Promise<IOffice | null>) | null = null;
    public onUpdate: ((office: IOffice, dto: IOfficeUpdateDto) => Promise<IOffice | null>) | null = null;

    public constructor() {
        super("office-modal", "");
        this.initializeControls();
    }

    public show(office?: IOffice): Promise<void> {
        this.office = office ?? null;
        this.title = office ? "Изменение филиала" : "Новый филиал";
        this.initializeControls();

        if (office) {
            this.populateControls(office);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.tbEmail = new TextBox();
        this.phPhone = new PhoneBox();
        this.tbAddress = new TextBox();
        this.cbCurrency = new Select<ISelectOption<Currency>>();
        this.cbLocale = new Select<ISelectOption<Locale>>();
        this.tbNumerationTemplate = new TextBox();
        this.lblNumerationExample = new Label();
        this.pnlNumerationTemplate = new Panel();
        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbName.id = "offices.name";
        this.tbName.label = "Наименование";
        this.tbName.validation = "required";
        this.tbName.text = "";

        this.tbDescription.id = "offices.description";
        this.tbDescription.label = "Описание";
        this.tbDescription.text = "";

        this.tbEmail.id = "offices.email";
        this.tbEmail.label = "Почта";
        this.tbEmail.validation = "email";
        this.tbEmail.text = "";

        this.phPhone.id = "offices.phone";
        this.phPhone.label = "Телефон";
        this.phPhone.text = "";

        this.tbAddress.id = "offices.address";
        this.tbAddress.label = "Адрес";
        this.tbAddress.text = "";

        this.cbCurrency.id = "offices.currency";
        this.cbCurrency.label = "Валюта";
        this.cbCurrency.items = CurrencyUtils.getOptions();
        this.cbCurrency.textField = opt => opt.name;
        this.cbCurrency.selectedIndex = 0;

        this.cbLocale.id = "offices.locale";
        this.cbLocale.label = "Языковой стандарт";
        this.cbLocale.help =
            "Определяет формат чисел, денег и др.\nНапример, отделение дробной части в числе с помощью запятой или точки и т.п.";
        this.cbLocale.items = LocaleUtils.getOptions();
        this.cbLocale.textField = opt => opt.name;
        this.cbLocale.descriptionField = opt => opt.description;
        this.cbLocale.selectedIndex = 0;

        this.tbNumerationTemplate.id = "offices.numerationTemplate";
        this.tbNumerationTemplate.label = "Шаблон номера заявки";
        this.tbNumerationTemplate.help =
            "Шаблон для нумерации заявок в таблице и документах. Можно использовать макросы заявки, например, %(Заявка.Номер).";
        this.tbNumerationTemplate.addTextChangedHandler(this.changeNumerationTemplate.bind(this));
        this.tbNumerationTemplate.text = "%(Заявка.Номер)";

        this.lblNumerationExample.id = "offices.numerationExample";
        this.lblNumerationExample.class = "text-sm -mt-0.5";

        this.pnlNumerationTemplate.addControls([this.tbNumerationTemplate, this.lblNumerationExample]);

        //

        this.btnCancel.id = "offices.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "offices.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(office: IOffice): void {
        this.tbName.text = office.info.name;
        this.tbDescription.text = office.info.description ?? "";
        this.tbEmail.text = office.info.contacts?.email ?? "";
        const numerationTemplate = office.settings?.numerationTemplate;
        this.tbNumerationTemplate.text =
            numerationTemplate && numerationTemplate.length > 0 ? numerationTemplate : "%(Заявка.Номер)";
        this.phPhone.text = office.info.contacts?.phone ?? "";
        this.tbAddress.text = office.info.contacts?.address ?? "";

        const currencyIndex = this.cbCurrency.items.findIndex(i => i.id === office.info.currency);
        this.cbCurrency.selectedIndex = currencyIndex >= 0 ? currencyIndex : 0;

        const localeIndex = this.cbLocale.items.findIndex(i => i.id === office.info.locale);
        this.cbLocale.selectedIndex = localeIndex >= 0 ? localeIndex : 0;
    }

    public get controls(): Control[] {
        return [
            this.tbName,
            this.tbDescription,
            this.tbEmail,
            this.phPhone,
            this.tbAddress,
            this.cbCurrency,
            this.cbLocale,
            this.pnlNumerationTemplate,
            this.pnlFooter,
        ];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.office ? await this.updateOffice(this.office) : await this.createOffice();

        if (result) {
            this.hide(result);
        }
    }

    private async createOffice(): Promise<IOffice | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: IOfficeCreateDto = {
            info: {
                name: this.tbName.text,
                //shortName: office.info.shortName,
                description: this.tbDescription.text,
                //avatar: office.info.avatar,
                currency: this.cbCurrency.selectedItem?.id as Currency,
                locale: this.cbLocale.selectedItem?.id as Locale,
                contacts: {
                    email: this.tbEmail.text,
                    phone: this.phPhone.text,
                    address: this.tbAddress.text,
                },
            },
            settings: {
                numerationTemplate: this.tbNumerationTemplate.text,
            },
        };

        return await this.onCreate(dto);
    }

    private async updateOffice(office: IOffice): Promise<IOffice | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IOfficeUpdateDto = {
            info: {
                name: this.tbName.text,
                //shortName: office.info.shortName,
                description: this.tbDescription.text,
                //avatar: office.info.avatar,
                currency: this.cbCurrency.selectedItem?.id as Currency,
                locale: this.cbLocale.selectedItem?.id as Locale,
                contacts: {
                    email: this.tbEmail.text,
                    phone: this.phPhone.text,
                    address: this.tbAddress.text,
                },
            },
            settings: {
                numerationTemplate: this.tbNumerationTemplate.text,
            },
        };

        return await this.onUpdate(office, dto);
    }

    private changeNumerationTemplate(sender: any, e: TextChangedEventArgs): void {
        let template = this.tbNumerationTemplate.text.trim();
        if (template.length === 0) {
            template = "%(Заявка.Номер)";
        }

        const printContext: OrderPrintContext = {
            company: this.demo.company,
            office: this.demo.office,
            order: this.demo.order,
            orderType: this.demo.orderType as IOrderType,
            productTypes: this.demo.productTypes as IProductType[],
        };

        const example = Printer.replaceOrderDocumentsGeneralMacros(template, printContext);

        this.lblNumerationExample.text = `Пример: Заявка № ${example}`;
    }
}
