import { IProject } from "@lib";

export function getTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (project: IProject) => context.openModalUpdate(project),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (project: IProject) => context.confirmDelete(project),
                        },
                    ],
                };
            },
        },
        {
            colId: "number",
            field: "number",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,
        },
        // {
        //     colId: "modules",
        //     headerName: "Модули",
        //     width: 140,
        //     hide: false,
        //     headerClass: "text-transparent",

        //     cellRendererFramework: "CellRendererChip",
        //     cellRendererParams: (params: any) => {
        //         return {
        //             color: "primary",
        //             name: "Модули",
        //             icon: "GridIcon",
        //             click: () => context.openModalModules(params.data),
        //         };
        //     },
        // },
        {
            colId: "name",
            field: "info.name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "description",
            field: "info.description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 500,
            hide: false,
        },
    ];
}
