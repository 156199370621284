import { OrderNumeration, SaleNumeration, TaskNumeration } from "@lib";
import { ISelectOption } from "@core/types/common/select-options";

export function getOrderNumerations(): ISelectOption<OrderNumeration>[] {
    return [
        {
            id: OrderNumeration.Office,
            name: "По филиалам",
            description: "В каждом филиале своя нумерация заявок",
        },
        {
            id: OrderNumeration.Company,
            name: "По компании",
            description: "Единая нумерация заявок для всех филиалов компании",
        },
    ];
}

export function getSaleNumerations(): ISelectOption<SaleNumeration>[] {
    return [
        {
            id: SaleNumeration.Shop,
            name: "По магазинам",
            description: "В каждом магазине своя нумерация продаж",
        },
        {
            id: SaleNumeration.Company,
            name: "По компании",
            description: "Единая нумерация продаж для всех магазинов компании",
        },
    ];
}

export function getTaskNumerations(): ISelectOption<TaskNumeration>[] {
    return [
        {
            id: TaskNumeration.Project,
            name: "По проектам",
            description: "В каждом проекте своя нумерация задач",
        },
        {
            id: TaskNumeration.Company,
            name: "По компании",
            description: "Единая нумерация задач для всех проектов компании",
        },
    ];
}
