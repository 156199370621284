import { IAccount, IEmployee, IOffice, IPermission, IProject, IShop, IStore } from "@lib";
import { IPermissionGrantDto, PermissionCommonSection, PermissionRight } from "@lib";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface EmployeeContext {
    offices: IOffice[];
    shops: IShop[];
    projects: IProject[];
    accounts: IAccount[];
    stores: IStore[];
    employees: IEmployee[];
}

export const Rights = {
    all(): PermissionRight[] {
        return [PermissionRight.Read, PermissionRight.Create, PermissionRight.Update, PermissionRight.Delete];
    },

    hasAll(rights: PermissionRight[]): boolean {
        return (
            rights.includes(PermissionRight.Read) &&
            rights.includes(PermissionRight.Create) &&
            rights.includes(PermissionRight.Update) &&
            rights.includes(PermissionRight.Delete)
        );
    },

    empty(): any {
        return {
            [PermissionRight.Read]: false,
            [PermissionRight.Create]: false,
            [PermissionRight.Update]: false,
            [PermissionRight.Delete]: false,
        };
    },

    emptyRead(): any {
        return {
            [PermissionRight.Read]: false,
        };
    },

    emptyCreateUpdateDelete(): any {
        return {
            [PermissionRight.Create]: false,
            [PermissionRight.Update]: false,
            [PermissionRight.Delete]: false,
        };
    },
};

export function makeEmptyPermissions(context: EmployeeContext): IPermissionGrantDto {
    const permissionsEmpty: IPermissionGrantDto = {
        admin: false,
        common: {
            [PermissionCommonSection.Clients]: Rights.emptyCreateUpdateDelete(),
            [PermissionCommonSection.Salaries]: Rights.emptyRead(),
            [PermissionCommonSection.Reports]: Rights.emptyRead(),
            [PermissionCommonSection.Employees]: Rights.emptyCreateUpdateDelete(),
            [PermissionCommonSection.Offices]: Rights.emptyCreateUpdateDelete(),
            [PermissionCommonSection.Shops]: Rights.emptyCreateUpdateDelete(),
            //[PermissionCommonSection.Projects]: Rights.emptyCreateUpdateDelete(),
            [PermissionCommonSection.Accounts]: Rights.emptyCreateUpdateDelete(),
            [PermissionCommonSection.Stores]: Rights.emptyCreateUpdateDelete(),
            [PermissionCommonSection.Lists]: Rights.emptyCreateUpdateDelete(),
            [PermissionCommonSection.Templates]: Rights.emptyCreateUpdateDelete(),
        },
        offices: {},
        shops: {},
        stores: {},
        accounts: {},
        employees: {},
    };

    for (const office of context.offices) {
        permissionsEmpty.offices[office.id] = {
            admin: false,
            [PermissionRight.OrdersRead]: false,
            [PermissionRight.OrdersCreate]: false,
            [PermissionRight.OrdersUpdate]: false,
            [PermissionRight.OrdersDelete]: false,
        };
    }

    for (const shop of context.shops) {
        permissionsEmpty.shops[shop.id] = {
            admin: false,
            [PermissionRight.SalesRead]: false,
            [PermissionRight.SalesCreate]: false,
            [PermissionRight.SalesUpdate]: false,
            [PermissionRight.SalesDelete]: false,
        };
    }

    // for (const project of context.projects) {
    //     permissionsEmpty.projects[project.id] = {
    //         admin: false,
    //         [PermissionRight.TasksRead]: false,
    //         [PermissionRight.TasksCreate]: false,
    //         [PermissionRight.TasksUpdate]: false,
    //         [PermissionRight.TasksDelete]: false,
    //     };
    // }

    for (const store of context.stores) {
        permissionsEmpty.stores[store.id] = {
            admin: false,
            [PermissionRight.GoodsRead]: false,
            [PermissionRight.GoodsCreate]: false,
            [PermissionRight.GoodsUpdate]: false,
            [PermissionRight.GoodsAsOrderMaterials]: false,
            [PermissionRight.GoodsSale]: false,
            [PermissionRight.GoodsDelete]: false,
        };
    }

    for (const account of context.accounts) {
        permissionsEmpty.accounts[account.id] = {
            admin: false,
            [PermissionRight.TransactionsRead]: false,
            [PermissionRight.TransactionsCreate]: false,
            //[PermissionRights.TransactionsUpdate]: false,
            [PermissionRight.TransactionsDelete]: false,
        };
    }

    for (const employee of context.employees) {
        permissionsEmpty.employees[employee.user as string] = {
            admin: false,
            [PermissionRight.SalaryRead]: false,
            [PermissionRight.SalaryCreate]: false,
            //[PermissionRights.SalaryUpdate]: false,
            [PermissionRight.SalaryDelete]: false,
        };
    }

    return permissionsEmpty;
}

export function simplifyPermissions(permissions: IPermissionGrantDto): IPermissionGrantDto {
    let simplify = JSON.parse(JSON.stringify(permissions)) as IPermissionGrantDto;

    if (simplify.admin) {
        simplify = { admin: true };
        return simplify;
    }

    for (const officeId in simplify.offices) {
        const office = simplify.offices[officeId];
        if (office.admin) {
            simplify.offices[officeId] = { admin: true };
        }
    }

    for (const shopId in simplify.shops) {
        const shop = simplify.shops[shopId];
        if (shop.admin) {
            simplify.shops[shopId] = { admin: true };
        }
    }

    for (const storeId in simplify.stores) {
        const store = simplify.stores[storeId];
        if (store.admin) {
            simplify.stores[storeId] = { admin: true };
        }
    }

    for (const accountId in simplify.accounts) {
        const account = simplify.accounts[accountId];
        if (account.admin) {
            simplify.accounts[accountId] = { admin: true };
        }
    }

    for (const userId in simplify.employees) {
        const employee = simplify.employees[userId];
        if (employee.admin) {
            simplify.employees[userId] = { admin: true };
        }
    }

    return simplify;
}
