import { Currency, Locale } from "@lib";
import { ISelectOption } from "@core/types/common/select-options";
import { Localizer } from "@/i18n/localizer";

export abstract class LocaleUtils {
    public static getTypes(): Locale[] {
        return [Locale.RU, Locale.EN];
    }

    public static getTypeText(type: Locale): string {
        switch (type) {
            case Locale.RU:
                return `Русский (${Locale.RU})`;
            case Locale.EN:
                return `Английский (${Locale.EN})`;
        }
    }

    public static getTypeDescription(type: Locale): string {
        switch (type) {
            case Locale.RU:
                return `Пример: ${Localizer.currency(12345.67, Currency.RUB, Locale.RU)}`;
            case Locale.EN:
                return `Пример: ${Localizer.currency(12345.67, Currency.USD, Locale.EN)}`;
        }
    }

    public static getOptions(): ISelectOption<Locale>[] {
        return LocaleUtils.getTypes().map(t => ({
            id: t,
            name: LocaleUtils.getTypeText(t),
            description: LocaleUtils.getTypeDescription(t),
        }));
    }
}
