import { IProject, IProjectCreateDto, IProjectUpdateDto } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Label, Panel, TextBox, TextChangedEventArgs } from "@core/components/alt-ui/controls";
// import {
//     createDemoCompany,
//     createDemoProject,
//     createDemoTask,
// } from "@core/controls/document-template-editor/preview-data";
// import { Printer } from "@core/usecases/template/printer";
// import { TaskPrintContext } from "@core/usecases/template/macro-replacers/task-document.macro-replacer";

export interface IProjectModalContext {
    /** Проект для редактирования. */
    project?: IProject;

    /** Обработчик создания проекта. */
    createHandler?: (dto: IProjectCreateDto) => Promise<boolean>;

    /** Обработчик обновления проекта. */
    updateHandler?: (project: IProject, dto: IProjectUpdateDto) => Promise<boolean>;
}

export class ProjectModal extends Modal<IProjectModalContext, boolean> {
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private tbNumerationTemplate!: TextBox;
    // private lblNumerationExample!: Label;
    // private pnlNumerationTemplate!: Panel;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    // private demo = {
    //     company: createDemoCompany(),
    //     project: createDemoProject(),
    //     task: createDemoTask(),
    // };

    private context?: IProjectModalContext;

    public constructor() {
        super("project-modal", "");
        this.initializeControls();
    }

    public show(context: IProjectModalContext): Promise<boolean> {
        this.context = context;
        this.title = context.project ? "Изменение проекта" : "Новый проект";
        this.initializeControls();

        if (context.project) {
            this.populateControls(context.project);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbName = new TextBox();
        this.tbName.id = "projects.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.tbDescription = new TextBox();
        this.tbDescription.id = "projects.description";
        this.tbDescription.label = "Описание";

        // this.tbNumerationTemplate = new TextBox();
        // this.tbNumerationTemplate.id = "projects.numerationTemplate";
        // this.tbNumerationTemplate.label = "Шаблон номера задачи";
        // this.tbNumerationTemplate.help =
        //     "Шаблон для нумерации задач в таблице и документах. Можно использовать макросы задач, например, %(Задача.Номер).";
        // this.tbNumerationTemplate.addTextChangedHandler(this.changeNumerationTemplate.bind(this));
        // this.tbNumerationTemplate.text = "%(Задача.Номер)";

        // this.lblNumerationExample = new Label();
        // this.lblNumerationExample.id = "projects.numerationExample";
        // this.lblNumerationExample.class = "text-sm -mt-0.5";

        // this.pnlNumerationTemplate = new Panel();
        // this.pnlNumerationTemplate.addControls([this.tbNumerationTemplate, this.lblNumerationExample]);

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "projects.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "projects.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(project: IProject): void {
        this.tbName.text = project.info.name;
        this.tbDescription.text = project.info.description ?? "";
        // const numerationTemplate = project.settings?.numerationTemplate;
        // this.tbNumerationTemplate.text =
        //     numerationTemplate && numerationTemplate.length > 0 ? numerationTemplate : "%(Задача.Номер)";
    }

    public get controls(): Control[] {
        return [
            this.tbName,
            this.tbDescription,
            //this.pnlNumerationTemplate,
            this.pnlFooter,
        ];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide(false);
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const ok = this.context?.project ? await this.updateProject(this.context.project) : await this.createProject();

        if (ok) {
            this.hide(true);
        }
    }

    private async createProject(): Promise<boolean> {
        if (!this.context?.createHandler) {
            return false;
        }

        const dto: IProjectCreateDto = {
            info: {
                name: this.tbName.text,
                //shortName: project.info.shortName,
                description: this.tbDescription.text,
            },
            // settings: {
            //     numerationTemplate: this.tbNumerationTemplate.text,
            // },
        };

        return await this.context.createHandler(dto);
    }

    private async updateProject(project: IProject): Promise<boolean> {
        if (!this.context?.updateHandler) {
            return false;
        }

        const dto: IProjectUpdateDto = {
            info: {
                name: this.tbName.text,
                //shortName: project.info.shortName,
                description: this.tbDescription.text,
            },
            // settings: {
            //     numerationTemplate: this.tbNumerationTemplate.text,
            // },
        };

        return await this.context.updateHandler(project, dto);
    }

    private changeNumerationTemplate(sender: any, e: TextChangedEventArgs): void {
        // let template = this.tbNumerationTemplate.text.trim();
        // if (template.length === 0) {
        //     template = "%(Задача.Номер)";
        // }
        // const printContext: TaskPrintContext = {
        //     company: this.demo.company,
        //     project: this.demo.project,
        //     task: this.demo.task,
        // };
        // const example = Printer.replaceTaskDocumentsGeneralMacros(template, printContext);
        // this.lblNumerationExample.text = `Пример: Задача № ${example}`;
    }
}
