import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { ICompany, IModule, IProject, IProjectCreateDto, IProjectUpdateDto, IUser } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { IProjectModalContext, ProjectModal } from "./modals/project.modal";
import { IProjectModulesModalContext, ProjectModulesModal } from "./modals/project-modules.modal";
import { getTableColumns } from "./settings-company-projects-defaults";
import { AppException } from "@/core/exceptions";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent },
})
export default class SettingsCompanyProjects extends Vue {
    private ModuleUseCase = this.$alt.system.usecase.createModuleUseCase();
    private ProjectUseCase = this.$alt.system.usecase.createProjectUseCase();

    private featureEnabled = false;
    private user!: IUser;
    private company!: ICompany;

    public modules: IModule[] = [];
    public projects: IProject[] = [];
    public projectModal = new ProjectModal();
    public projectModulesModal = new ProjectModulesModal();

    public get columns(): any[] {
        return getTableColumns(this);
    }

    public get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Projects, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Projects, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Projects, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.featureEnabled = this.company.features?.projects?.enabled ?? false;
            await this.selectModules();
            await this.selectProjects();
            await this.initParams();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$alt.queryExits("new")) {
                this.openModalCreate();
                return;
            }

            const projectId = this.$alt.queryGetFirst("id");
            if (projectId) {
                const project = await this.ProjectUseCase.get(this.company.id, projectId);
                this.openModalUpdate(project);
            }
        } finally {
            await this.$router.push({ query: undefined }).catch(_ => {});
        }
    }

    public async openModalCreate(): Promise<void> {
        const context: IProjectModalContext = {
            createHandler: dto => this.$alt.longOperation(() => this.create(dto), "Проект успешно создан."),
        };

        const success = await this.projectModal.show(context);

        if (success) {
            await this.$alt.longOperation(async () => {
                await this.selectProjects();
                this.selectProjectsForUser();
            });
        }
    }

    public async openModalUpdate(project: IProject): Promise<void> {
        const context: IProjectModalContext = {
            project: project,
            updateHandler: (orig, dto) =>
                this.$alt.longOperation(() => this.update(orig, dto), "Проект успешно изменён."),
        };

        const success = await this.projectModal.show(context);

        if (success) {
            await this.$alt.longOperation(async () => {
                await this.selectProjects();
                this.selectProjectsForUser();
            });
        }
    }

    public async confirmDelete(project: IProject): Promise<void> {
        const answer = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить проект: "${project.info.name}"?`,
            "Удаление проекта",
            { acceptText: "Удалить" },
        );

        if (answer) {
            await this.$alt.longOperation(async () => {
                await this.delete(project);
                this.$alt.toast.success("Проект успешно удалён.");
                await this.selectProjects();
                this.selectProjectsForUser();
            });
        }
    }

    public async openModalModules(project: IProject): Promise<void> {
        const context: IProjectModulesModalContext = {
            modules: this.modules,
            project: project,
            // updateHandler: (good, dto) =>
            //     this.$alt.longOperation(() => this.update(good, dto), "Проект успешно изменён."),
        };

        const success = await this.projectModulesModal.show(context);

        if (success) {
            await this.$alt.longOperation(async () => {
                await this.selectProjects();
                this.selectProjectsForUser();
            });
        }
    }

    private async selectModules(): Promise<void> {
        try {
            if (!this.featureEnabled) {
                return;
            }

            const result = await this.ModuleUseCase.select();
            this.modules = result.data;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить модули:\n${e.message}`);
        }
    }

    private async selectProjects(): Promise<void> {
        try {
            if (!this.featureEnabled || !this.can.read) {
                return;
            }

            this.projects = await this.ProjectUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить проекты:\n${e.message}`);
        }
    }

    private async selectProjectsForUser(): Promise<void> {
        const projects = await this.$alt.system.usecase
            .createEmployeeUseCase()
            .selectProjects(this.company.id, this.user.id);
        this.$info.setProjects(projects);
    }

    private async create(dto: IProjectCreateDto): Promise<void> {
        try {
            await this.ProjectUseCase.create(this.company.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось создать проект:\n${e.message}`);
        }
    }

    private async update(project: IProject, dto: IProjectUpdateDto): Promise<void> {
        try {
            const sh = await this.ProjectUseCase.update(this.company.id, project.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось изменить проект:\n${e.message}`);
        }
    }

    private async delete(project: IProject): Promise<void> {
        try {
            await this.ProjectUseCase.delete(this.company.id, project.id);
        } catch (e: any) {
            throw new AppException(`Не удалось удалить проект:\n${e.message}`);
        }
    }
}
