import { Vue, Component } from "vue-property-decorator";
import {
    BAlert,
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
    BFormInput,
    BInputGroup,
    BFormGroup,
} from "bootstrap-vue";
import { BButton, BListGroup, BListGroupItem, BCardText, BPagination } from "bootstrap-vue";
import { ICompany, ICompanyPayment, CompanyPaymentItem, CompanyPricingPlan, ISelectQuery, IUser } from "@lib";
import { CompanyUseCase } from "@/@core/usecases/company/company.usecase";
import YoomoneyForm from "@/views/main/settings/settings-company/yoomoney/yoomoneyForm.vue";
import { IYoomoney } from "@/views/main/settings/settings-company/yoomoney/yoomoney";
import CloudPaymentsForm from "@/views/main/settings/settings-company/cloud-payments/cloud-payments.form.vue";

function paymentComment(payment: ICompanyPayment): string {
    switch (payment.item) {
        case CompanyPaymentItem.Deposit:
            return "Внесение средств";
        case CompanyPaymentItem.Withdrawal:
            return payment.meta === "sms" ? "Отправка смс" : "Списание средств";
        case CompanyPaymentItem.Promo:
            if (payment.comment) {
                return payment.comment;
            }

            return "Акция";
    }

    return "";
}

Vue.filter("paymentComment", paymentComment);

@Component({
    components: {
        BAlert,
        BCard,
        BTableSimple,
        BThead,
        BTbody,
        BTfoot,
        BTr,
        BTh,
        BTd,
        BButton,
        BListGroup,
        BListGroupItem,
        BFormInput,
        BInputGroup,
        BFormGroup,
        BCardText,
        BPagination,
        YoomoneyForm,
        CloudPaymentsForm,
    },
})
export default class SettingsCompanyPayment extends Vue {
    private loaded = false;
    private company!: ICompany;
    private user!: IUser;
    private balance = 0;
    private pricingPlan = CompanyPricingPlan.Free10;
    private officesCount: number = 0;
    private shopsCount: number = 0;
    private projectsCount: number = 0;
    private projectsFeatureEnabled = false;

    private paymentsLoaded: boolean = false;
    private payments: ICompanyPayment[] = [];
    private paymentsTotal: number = 0;
    private paymentsPage: number = 1;
    private paymentsPerPage: number = 10;

    private priceStandart: number = 17;
    private priceSC24: number = 12;

    private showYoomoney = false;
    private minSum = 500;

    private ym: IYoomoney = {
        receiver: process.env.VUE_APP_YOOMONEY_WALLET_ID ?? "",
        targets: "Оплата доступа",
        paymentType: "AC",
        sum: "1000",
        label: "",
        successURL: window.location.origin,
    };
    private cp: any = {
        publicId: process.env.VUE_APP_CLOUDPAYMENT_PUBLICID ?? "",
        description: "Оплата доступа Alt SC",
        amount: "1000",
    };

    private get pricingPlanDate(): Date | undefined {
        return this.company?.pricing?.date;
    }

    private get pricingPlanTitle(): string {
        switch (this.pricingPlan) {
            case CompanyPricingPlan.Standart:
                return "Стандартный";
            case CompanyPricingPlan.SC24:
                return "Миграция с SC24";
            case CompanyPricingPlan.Free10:
            default:
                return "Знакомство с Alt SC";
        }
    }

    private get pricingPlanDescription(): string {
        switch (this.pricingPlan) {
            case CompanyPricingPlan.Standart:
                return `${this.priceStandart} ₽ / день за одну точку.`;
            case CompanyPricingPlan.SC24:
                return "Как в SC24: 12 ₽ / день.";
            case CompanyPricingPlan.Free10:
            default:
                return "Первые 10 заявок и 10 продаж бесплатно.";
        }
    }

    private get pricingPlanPrice(): number {
        switch (this.pricingPlan) {
            case CompanyPricingPlan.Standart:
                return this.priceStandart;
            case CompanyPricingPlan.SC24:
                return this.priceSC24;
            case CompanyPricingPlan.Free10:
            default:
                return 0;
        }
    }

    private get locale(): string {
        return "ru-RU";
    }

    private get placesCount(): number {
        return Math.max(this.officesCount, this.shopsCount, this.projectsCount);
    }

    private get paymentSkip(): number {
        return (this.paymentsPage - 1) * this.paymentsPerPage;
    }

    public async mounted(): Promise<void> {
        await this.init();
    }

    private async init(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.company = await this.$info.getCompany();
            this.user = await this.$info.getUser();
            this.balance = this.company.balance;
            this.pricingPlan = this.company.pricing?.plan ?? CompanyPricingPlan.Free10;
            this.projectsFeatureEnabled = this.company.features?.projects?.enabled ?? false;
            const offices = await this.$info.getOffices();
            const shops = await this.$info.getShops();
            const projects = await this.$info.getProjects();
            this.officesCount = offices.length;
            this.shopsCount = shops.length;
            this.projectsCount = this.projectsFeatureEnabled ? projects.length : 0;
            await this.initData();
            await this.initParams();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async initData(): Promise<void> {
        await this.getCompanyPayments();

        this.ym.successURL = window.location.origin + this.$route.path;
        this.ym.label = this.company.id;
        this.ym.targets = `Оплата доступа компании ${this.company.info.name}`;
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$route.query.minsum) {
                this.minSum = parseFloat(this.$route.query.minsum as string);
                if (isNaN(this.minSum)) {
                    this.minSum = 500;
                }
            }

            if (this.$route.query.ym) {
                this.showYoomoney = true;
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            //throw new Error("Параметр не найден.");
        }
    }

    private async getCompanyPayments(): Promise<void> {
        try {
            const limit = this.paymentsPerPage;
            const offset = this.paymentSkip;
            const query: ISelectQuery = { limit, offset };
            const result = await this.$alt.system.usecase.createCompanyPaymentUseCase().select(this.company.id, query);
            this.payments = result.data;
            this.paymentsTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить платежи:\n${e.message}`);
        }
    }

    private async testPayment(): Promise<void> {
        try {
            const dto = {
                notification_type: "card-incoming",
                zip: "",
                bill_id: "",
                amount: "9.80",
                firstname: "",
                codepro: "false",
                withdraw_amount: "10.00",
                city: "",
                unaccepted: "false",
                label: "60230f782f6435d5909f443e",
                building: "",
                lastname: "",
                datetime: "2021-10-14T21:11:36Z",
                suite: "",
                sender: "",
                phone: "",
                sha1_hash: "4792ae612e695bd760310745a724bd54892eb8e9",
                street: "",
                flat: "",
                fathersname: "",
                operation_label: "28fab0b2-0011-5000-8000-1b3d65ce5b30",
                operation_id: "687561096719004208",
                currency: "643",
                email: "",
            };

            await new CompanyUseCase().testPayment(dto);

            await this.initData();
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось произвести оплату:\n${e.message}`);
        }
    }

    private async changePaymentsPage(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.getCompanyPayments();
            this.paymentsLoaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
