import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Html, Icon, List, Panel } from "@core/components/alt-ui/controls";
import { IModule, IProject, IProjectModuleCreateDto } from "@lib";

export interface IProjectModulesModalContext {
    modules: IModule[];
    project: IProject;
    saveHandler?: (dto: IProjectModuleCreateDto[]) => Promise<boolean>;
}

type ProjectModuleItem = {
    module: IModule;
    settings: any;
};

export class ProjectModulesModal extends Modal<IProjectModulesModalContext, boolean> {
    private listItems!: List<ProjectModuleItem>;
    private btnAddModule!: Button;

    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private context?: IProjectModulesModalContext;

    public constructor() {
        super("project-modules-modal", "Модули");
        this.initializeControls();
    }

    public show(context: IProjectModulesModalContext): Promise<boolean> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    protected initializeControls(): void {
        this.listItems = new List<ProjectModuleItem>();
        this.listItems.id = "project-modules.list";
        this.listItems.class = "mt-1";
        this.listItems.items = [];
        this.listItems.header = i => i.module.name;
        this.listItems.content = i => {
            const content = new Html();
            content.id = `project-modules.content-${i.module.id}`;
            content.class = "mt-0.5";
            content.html = "";
            return content;
        };
        this.listItems.right = i => {
            const btnSettings = new Button();
            btnSettings.id = `project-modules.settings-${i.module.id}`;
            btnSettings.help = "Настроить";
            btnSettings.variant = "flat-primary";
            btnSettings.icon = new Icon();
            btnSettings.icon.icon = "SettingsIcon";
            // btnLink.addClickHandler((s, e) => this.open(item));
            return btnSettings;
        };
        // this.listItems.footer = item => {
        //     return filters.datetime.datetime(item.datetime, "L LT");
        // };

        this.btnAddModule = new Button();
        this.btnAddModule.id = "project-modules.add";
        this.btnAddModule.class = "w-100";
        this.btnAddModule.text = "Добавить модуль";
        this.btnAddModule.variant = "flat-primary";
        // this.btnAddModule.addClickHandler(() => this.load());

        this.btnCancel = new Button();
        this.btnCancel.id = "project-modules.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(() => this.hide(false));

        this.btnSave = new Button();
        this.btnSave.id = "project-modules.save";
        this.btnSave.text = "Сохранить";
        // this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    public get controls(): Control[] {
        return [this.listItems, this.btnAddModule, this.pnlFooter];
    }
}
