import { IStore, IStoreCreateDto, IStoreUpdateDto, Currency, Locale } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, Select, TextBox } from "@core/components/alt-ui/controls";
import { ISelectOption } from "@core/types/common/select-options";
import { CurrencyUtils } from "@/utils/types/currency.utils";
import { LocaleUtils } from "@/utils/types/locale.utils";

export class StoreModal extends Modal<IStore> {
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private cbCurrency!: Select<ISelectOption<Currency>>;
    private cbLocale!: Select<ISelectOption<Locale>>;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private store: IStore | null = null;
    public onCreate: ((dto: IStoreCreateDto) => Promise<IStore | null>) | null = null;
    public onUpdate: ((store: IStore, dto: IStoreUpdateDto) => Promise<IStore | null>) | null = null;

    public constructor() {
        super("store-modal", "");
        this.initializeControls();
    }

    public show(store?: IStore): Promise<void> {
        this.store = store ?? null;
        this.title = store ? "Изменение склада" : "Новый склад";
        this.initializeControls();

        if (store) {
            this.populateControls(store);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.cbCurrency = new Select<ISelectOption<Currency>>();
        this.cbLocale = new Select<ISelectOption<Locale>>();
        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbName.id = "stores.name";
        this.tbName.label = "Наименование";
        this.tbName.validation = "required";
        this.tbName.text = "";

        this.tbDescription.id = "stores.description";
        this.tbDescription.label = "Описание";
        this.tbDescription.text = "";

        this.cbCurrency.id = "stores.currency";
        this.cbCurrency.label = "Валюта";
        this.cbCurrency.items = CurrencyUtils.getOptions();
        this.cbCurrency.textField = opt => opt.name;
        this.cbCurrency.selectedIndex = 0;

        this.cbLocale.id = "stores.locale";
        this.cbLocale.label = "Языковой стандарт";
        this.cbLocale.help =
            "Определяет формат чисел, денег и др.\nНапример, отделение дробной части в числе с помощью запятой или точки и т.п.";
        this.cbLocale.items = LocaleUtils.getOptions();
        this.cbLocale.textField = opt => opt.name;
        this.cbLocale.descriptionField = opt => opt.description;
        this.cbLocale.selectedIndex = 0;

        //

        this.btnCancel.id = "stores.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "stores.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(store: IStore): void {
        this.tbName.text = store.info.name;
        this.tbDescription.text = store.info.description ?? "";

        const currencyIndex = this.cbCurrency.items.findIndex(i => i.id === store.info.currency);
        this.cbCurrency.selectedIndex = currencyIndex >= 0 ? currencyIndex : 0;

        const localeIndex = this.cbLocale.items.findIndex(i => i.id === store.info.locale);
        this.cbLocale.selectedIndex = localeIndex >= 0 ? localeIndex : 0;
    }

    public get controls(): Control[] {
        return [this.tbName, this.tbDescription, this.cbCurrency, this.cbLocale, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.store ? await this.updateStore(this.store) : await this.createStore();

        if (result) {
            this.hide(result);
        }
    }

    private async createStore(): Promise<IStore | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: IStoreCreateDto = {
            name: this.tbName.text,
            //shortName: store.info.shortName,
            description: this.tbDescription.text,
            //avatar: store.info.avatar,
            currency: this.cbCurrency.selectedItem?.id as Currency,
            locale: this.cbLocale.selectedItem?.id as Locale,
        };

        return await this.onCreate(dto);
    }

    private async updateStore(store: IStore): Promise<IStore | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IStoreUpdateDto = {
            name: this.tbName.text,
            //shortName: store.info.shortName,
            description: this.tbDescription.text,
            //avatar: store.info.avatar,
            currency: this.cbCurrency.selectedItem?.id as Currency,
            locale: this.cbLocale.selectedItem?.id as Locale,
        };

        return await this.onUpdate(store, dto);
    }
}
